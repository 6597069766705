
import { defineComponent, nextTick } from 'vue'
import { UserController, AboController, PaymentController, NewsletterController, InvoiceController } from '@/controller';
import { Dialog, PageHeader, Snackbar  } from '@/components/';
import { Button } from '@/ui';
import { DeviceService, CookieService } from '@/utils';

export default defineComponent({
    name: "Account",
    components: { Snackbar, Button, Dialog, PageHeader },
    data(){
        return{
            user: {} as any,
            mode: 'detailsMode',
            icons:{
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
                account: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 497 540.8" enable-background="new 0 0 497 540.8" xml:space="preserve"><g><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c31.4-30.1,50.9-72.3,50.9-119.2C414,99.2,340,25.1,248.6,25.1S83.2,99.2,83.2,190.5c0,46.9,19.5,89.1,50.9,119.2"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c-29.9,28.6-70.1,46.4-114.8,46.4s-84.9-17.8-114.6-46.4"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M133.8,309.8C73.8,355.4,31.6,435.7,24,529.5"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c60,45.7,102.2,125.9,109.9,219.7"/></g></svg>', 
                letter: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 263.5" style="enable-background:new 0 0 480 263.5;" xml:space="preserve"><style type="text/css">.st0{display:none;}.st1{display:inline;fill:none;stroke:#163D64;stroke-width:25;stroke-linejoin:round;stroke-miterlimit:10;}.st2{fill:none;stroke:#163D64;stroke-width:25;stroke-linejoin:round;stroke-miterlimit:10;}</style><g class="st0"><path class="st1" d="M32.1,250.9c51.7-40.6,102.4-80.4,153.6-120.6c9.2,6.1,18.3,12.1,27.3,18.1c7,4.6,14,9.2,20.9,14c4.3,3,8,2.9,12.3,0c14.2-9.6,28.5-19,42.7-28.5c1.7-1.1,3.3-2.2,5.5-3.5c50.9,40,101.7,79.9,153.6,120.6C308.9,250.9,171.4,250.9,32.1,250.9z"/><path class="st1" d="M35.2,12.5c137,0,272.7,0,408.4,0c0.4,0.4,0.8,0.8,1.2,1.2c-1.3,0.6-2.6,1.1-3.8,1.9C377.8,57.8,314.7,100,251.5,142.2c-3.8,2.5-7.7,6.7-11.5,6.7c-3.9,0-7.8-4.2-11.5-6.8C165.2,100,102.1,57.7,38.9,15.5C37.9,14.8,37,14,35.2,12.5z"/><path class="st1" d="M173.6,122.1c-51.3,40.3-101.8,80-153.1,120.3c0-74.3,0-147.9,0-222.7C71.5,53.8,122.1,87.6,173.6,122.1z"/><path class="st1" d="M306.4,122.1c51.5-34.4,101.9-68.2,153.2-102.5c0,74.5,0,148.2,0,222.8C408.4,202.2,357.8,162.4,306.4,122.1z"/></g><g><rect x="12.5" y="12.5" class="st2" width="455" height="238.5"/><polyline class="st2" points="467.5,12.5 240,157.5 12.5,12.5 "/><line class="st2" x1="12.5" y1="251" x2="184.5" y2="122.1"/><line class="st2" x1="467.5" y1="251" x2="295.5" y2="122.1"/></g></svg>',
                logout: '<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621 444l43-43 176 176-174 174Z"/></svg>',
                edit: '<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 876h44l443-443-44-44-443 443v44Zm614-486L666 262l42-42q17-17 42-17t42 17l44 44q17 17 17 42t-17 42l-42 42Zm-42 42L248 936H120V808l504-504 128 128Zm-107-21-22-22 44 44-22-22Z"/></svg>',
                password: '<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M80 856v-61h800v61H80Zm38-254-40-22 40-68H40v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 22-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Z"/></svg>',
                watch: '<svg width="34" height="18" viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="outline" d="M33 9C31.5314 10.7009 25.7359 17 17 17C15.0703 17 7.64824 16.6924 1 9C2.46546 7.30219 8.26096 1 17 1C18.9297 1 26.3518 1.30757 33 9Z" stroke="#163D64" stroke-width="1.5" stroke-miterlimit="10"/><path class="point" d="M17.0001 15.2223C19.9456 15.2223 22.3334 12.4365 22.3334 9.00005C22.3334 5.56362 19.9456 2.77783 17.0001 2.77783C14.0546 2.77783 11.6667 5.56362 11.6667 9.00005C11.6667 12.4365 14.0546 15.2223 17.0001 15.2223Z" fill="#163D64"/></svg>',
                downloadBill: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" enable-background="new 0 0 250 250" xml:space="preserve"><g><g><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M169.1,180.5V199c0,2.7-2.2,5-5,5H85.9c-2.7,0-5-2.2-5-5v-18.5"/><polyline fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="162.6,142.9 125.1,180.5 87.4,142.9 "/><line fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="125" y1="86" x2="125" y2="180.5"/></g><g><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M155.4,9.2H41.4c-14.8,0-27,12.1-27,27v177.7c0,14.8,12.1,27,27,27h167.3c14.8,0,27-12.1,27-27V83L155.4,9.2z"/><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M235.6,84.6c-27.1,0-54.2,0-81.3,0c0-25,0-50,0-75"/></g></g></svg>',
                download: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" enable-background="new 0 0 250 250" xml:space="preserve"><g><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M210.5,194.2v35.9c0,5.2-4.3,9.7-9.7,9.7H49.2c-5.2,0-9.7-4.3-9.7-9.7v-35.9"/><polyline fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="197.7,121.3 125,194.2 52.1,121.3 "/><line fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="125" y1="11" x2="125" y2="194.2"/></g></svg>',
            },
            validateForm: false,
            errors:0,
            waitForResponse: false,
            loader: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform="translate(1 1)"><circle class="st0" cx="18" cy="18" r="18"/><path class="st1" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>',
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            abos: [] as Array<any>,
            payments: [] as Array<any>,
            showConfirmDeleteAccount: false,
            showAllPayments: false,
            sortedPayments: [] as Array<any>,
            showMoreSettings: false,
            successorUserAbo: {} as any,
            showLicencingLink: false
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.fetchData();
    },
    computed:{
        getLastPayment(){
            if(this.payments && this.payments.length >0){
                    let lastPayment :any = this.payments[0];
                    this.payments.forEach((payment:any) =>{
                        if(payment.payment.paymentTimestamp > lastPayment.payment.paymentTimestamp){
                            lastPayment =payment
                        }
                    })
                    return lastPayment;
            } else {
                return false
            }
        }
    },
    watch:{
        payments(){
            this.sortedPayments = this.payments.sort(function(x, y){
                return y.payment.paymentTimestamp - x.payment.paymentTimestamp;
            })
        }
    },
    methods: {
        getPriceRaw() {
            let price = 0;
            if(this.abos.length >0 && this.user.abo){
                this.abos.forEach((abo:any) => {
                    if(abo.uid==this.user.abo.uid){
                        price = abo.price
                    }
                });
            }
            return price
        },
        getAboPrice(){
            const price = this.getPriceRaw()
            let interval = ''
            if (this.user.abo.paymentInterval == 1) {
                interval = this.$t('abo.month')
            } else if (this.user.abo.paymentInterval == 2) {
                interval = this.$t('abo.year')
            } else if (this.user.abo.paymentInterval == 3) {
                interval = this.$t('abo.quarter')
            } else if (price > 0) {
                interval = this.$t('abo.once')
            }
            return price.toFixed(2) + ' €' + (price != 0 ? '/' + interval : '')
        },
        handleSetMode(mode:any){
            if(mode=='editMode'){
                this.$router.push('/edituser')
            } else if (mode=='pwChangeMode'){
                this.$router.push('/changepassword')
            } else if (mode=='manageAbo'){
                this.$router.push('/manageabo')
            }
        },
        getPaymentStatus(statuscode:any){
            switch (statuscode) {
                case 1:
                    return this.$t('status.prepared')
                case 2:
                    return this.$t('status.pending')
                case 3:
                    return this.$t('status.success')
                case 4:
                    return this.$t('status.canceled')
                default:
                    return "unknown";
            }
        },
        formatDate(ts:any){            
            if(ts){
                const date = new Date(ts*1000) ;
                return date.toLocaleDateString()
            }
            return '--'
        },
        handleToggleConfirmDeleteAccount(){
            this.showConfirmDeleteAccount =  !this.showConfirmDeleteAccount
        },
        handleToggleShowAllPayments(){
            this.showAllPayments = !this.showAllPayments

            if(this.showAllPayments){
                nextTick(() => {
                (this.$refs.tableWrapper  as any).scrollIntoView({ behavior: "smooth" }) as any
                })
            }
        },
        handleToggleMoreSettings() {
            const cookie = CookieService.readCookie('_alprelax_show_licence')
            this.showLicencingLink = cookie == 'true'
            this.showMoreSettings = !this.showMoreSettings
        },
        /*------------------ USER ------------------*/
        async fetchData(){
            this.user = UserController.user
            this.user = await UserController.getUser()
            this.getAbos()
            this.getPayments()
        },        
        async getAbos(){
            await AboController.getAbos();
            this.abos = AboController.abos;
            if (this.user.abo.aboSuccessor) this.successorUserAbo = this.abos.find(item => item.uid == this.user.abo.aboSuccessor)
        },
        async getPayments(){
            await PaymentController.getPayments();
            this.payments = PaymentController.payments;
        },        
        /* async logoutUser(){
            let res = await UserController.logoutUser()
            if(res.data.success){
                this.$router.push("/login")
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        }, */
        async handleDeleteAccount(){
            this.handleToggleConfirmDeleteAccount()
            let res = await UserController.deleteAccount()
            if(res.data.success){
                    //now send the logout to delete the cookie
                await UserController.logoutUser()
                this.$router.push("/login")
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
        async handleChangeNewsletter() {
            let res;
            if (this.user.newsletter) {
                res = await NewsletterController.unsubscribeNewsletter()
            } else {
                res = await NewsletterController.subscribeNewsletter()
            }
                //TODO: quick and dirty
            if (res) {
                if (res?.data?.data) {
                    this.user.newsletter = res.data.data.newsletterSubscription;
                }
                //console.log("Account:handleChangeNewsletter:", res, res.data.data.newsletterSubscription);
            }
        },
        /*--------------General-----------------*/
        async handleDownloadInvoice(invoiceId: number){
            console.log('handleDownloadInvoice ', invoiceId)
            let pdfWindow
            if (navigator.userAgent.match(/iPhone/i)) {
                pdfWindow = window.open("") as any
            }
            const res = await InvoiceController.downloadInvoice(invoiceId/*, pdfWindow */)
            if (res) {
                if (DeviceService.appleMobile && DeviceService.browser == 'Safari') {
                    //this.$router.push('/invoice');
                    //const height = window.innerHeight - 48;
                    const closeIcon = '<i class="svgicon svgicon--close" style="width: 40px; height: 40px; display: block; padding: 0; margin: 0;"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="159.914 89.916 380.172 380.172"><path fill="#ffffff" d="M179.83,93.332l170.16,170.16l170.18-170.16c4.555-4.555,11.941-4.555,16.5,0c4.555,4.559,4.555,11.944,0,16.5l-170.18,170.16l170.18,170.18c4.555,4.555,4.555,11.94,0,16.5c-4.559,4.555-11.945,4.555-16.5,0l-170.18-170.18l-170.16,170.18c-4.555,4.555-11.941,4.555-16.5,0c-4.555-4.559-4.555-11.945,0-16.5l170.16-170.18l-170.16-170.16c-4.555-4.555-4.555-11.941,0-16.5C167.889,88.778,175.275,88.778,179.83,93.332L179.83,93.332z"/></svg></i>'
                    const buttonStyle = `style='
                        all: unset;
                        color: #163D64; 
                        background-color: #163D64; 
                        position: fixed; 
                        bottom: 32px; 
                        right: 32px; 
                        width: 96px; 
                        height: 96px; 
                        padding: 28px; 
                        box-shadow: none; 
                        outline-width: 0; 
                        outline-color: transparent;
                        border-radius: 50%; 
                        outline: none; 
                        display: block; 
                        cursor: pointer; 
                        z-index: 1000;   
                        border: 0;
                        box-sizing: border-box;           
                        overflow: hidden;  
                        margin: 0;'
                    `
                    let bodyStr = "<body style='height: 100vH; overflow: hidden;'>"
                    bodyStr += "<embed width='100%' height='100%;' src='data:application/pdf;base64, " + encodeURI(InvoiceController.pdfData)+"#toolbar=1&navpanes=0&scrollbar=0'></embed>";
                    bodyStr += "<button " + buttonStyle + " onclick='javascript:window.close();'>" + closeIcon + "</button>"
                    bodyStr += "</body></html>"
                    pdfWindow.document.write("<html><head><title>"+InvoiceController.pdfFileName+"</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
                    pdfWindow.document.write(bodyStr); 
                } else {
                    const linkSource = InvoiceController.pdfSource
                    const downloadLink = document.createElement("a")
                    downloadLink.href = linkSource
                    downloadLink.target = '_top'
                    downloadLink.download = InvoiceController.pdfFileName
                    downloadLink.click()                
                } 
            }
            console.log('Todo handleDownloadInvoice ')
            //this.handleShowSnackbar("Funktion wurde noch nicht implementiert", true)
        },
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
            }, 5000)
        },
        getCancellationDate(expirationDate: number) {
            return expirationDate - this.user.clientSettings.cancellationLeadTime * 86400
        },
        getInvoiceNumberString(invoice: any) {
            if (invoice && invoice.invoiceNumber) {
                let leading = ''
                if (invoice.invoiceNumber < 10) {
                    leading = '000'
                } else if (invoice.invoiceNumber < 100) {
                    leading = '00'
                } else if (invoice.invoiceNumber < 1000) {
                    leading = '0'
                }
                return leading + invoice.invoiceNumber + ' / ' + invoice.year
            } else {
                return ''
            }
            
        }
    }
})
